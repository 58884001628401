import React, { useContext, useEffect, useState } from 'react';
import Column from './Column';
import Number from './Number';

import { bookContext } from './BookContext';
import { sizeContext } from './SizeContext';

import { MEDIA_URL } from '../config/general';

import "./styles/page.css";

export const Page = () => {

  const { layout, bg, pagenumber } = useContext(bookContext);
  const { appSize } = useContext(sizeContext);
  const [pageHeight, setPageHeight] = useState(0);
  
  useEffect(()=> {
    if (appSize[1] > appSize[0] * 0.5625 ){
      setPageHeight((appSize[0] * 0.5625) * 0.94);
    }
    else{
      setPageHeight(appSize[1]);
    }
  }, [pageHeight,appSize])

  const style = {
    backgroundImage: `url(${MEDIA_URL}/${ bg })`,
    height: `${pageHeight}px`
  }

  const columnLayout = () => {
    if (layout === 0){
      return (
        <>
          <Column type={'vertical-1'} columnText={ true } />
          <Column type={'vertical-1'} />
          <Column type={'vertical-1'} />
        </>
            
      );
    }
    else if (layout === 1){
      return (
        <>
            <Column type={'vertical-1'} />
            <Column type={'vertical-1'} columnText={ true } />
            <Column type={'vertical-1'} />
        </>
      );
    }
    else if (layout === 2){
      return (
        <>
            <Column type={'vertical-1'} />
            <Column type={'vertical-1'} />
            <Column type={'vertical-1'} columnText={ true } />
        </>
      );
    }
    else if (layout === 3){
      return (
        <>
            <Column type={'vertical-2'} columnText={ true } />
            <Column type={'vertical-1'} />
        </>
      );
    }
    else if (layout === 4){
      return (
        <>
            <Column type={'vertical-1'} />
            <Column type={'vertical-2'} columnText={ true } />
        </>
      );
    }
    else if (layout === 5){
      return (
        <>
            <Column type={'vertical-1_5'} columnText={ true } />
            <Column type={'vertical-1_5'} />
        </>
      );
    }
    else if (layout === 6){
      return (
        <>
            <Column type={'vertical-1_5'} />
            <Column type={'vertical-1_5'} columnText={ true } />
        </>
      );
    }
    else if (layout === 7){
      return (
        <>
            <Column type={'vertical-3'} columnText={ true } />
        </>
      );
    }
    else if (layout === 8){
      return (
        <>
            <Column type={'vertical-3'} />
        </>
      );
    }
  }

  return(
    <div style={ style } className='page-container page-horizontal'>
      { columnLayout() }
      <Number number = { pagenumber } />
    </div>
  )

}

export default Page;

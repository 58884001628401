import React from 'react'
import "./styles/number.css"

const Number = ( { number} ) => {
  return (
    <div className={'page-number'}>
        <span>
            { number }
        </span>
    </div>
  )
}

export default Number
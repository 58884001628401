import React, { useContext } from 'react'
import "./styles/column.css";
import TextReader from './TextReader';

import { bookContext } from './BookContext';

const Column = ({ type, columnText }) => {
  const { align, text, audiofile, pagePosition } = useContext(bookContext);

  const transcript = {
    words: JSON.parse(text)
  }

  return (
    <div className={ columnText ? type + ' text ' + align : type } >
      { columnText ? <TextReader audiofile={ audiofile } transcript={ transcript } pagePosition={ pagePosition } />: <></> }
    </div>
  )
}

export default Column
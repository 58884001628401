import React, {useContext} from 'react'
import "./styles/cover.css"

import { sizeContext } from './SizeContext';

import { MEDIA_URL } from '../config/general';

const Cover = ({ cover_img, book_id }) => {

    const { appSize } = useContext(sizeContext);

    const style = {
        backgroundImage: `url(${MEDIA_URL}/${ cover_img })`,
        width: (appSize[0] * 0.35) + 'px',
        height: ((appSize[0] * 0.35) * 0.5625) + 'px'
      }

    return (
        <a href={ "?book=" + book_id }>
            <div style={ style } className='book-cover' >
            </div>
        </a>
        
    )
}

export default Cover
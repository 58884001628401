import React, {useState, useEffect, useRef, useContext} from 'react'

import { navContext } from './NavContext';

import { MEDIA_URL } from '../config/general';

import "./styles/text_reader.css";

import playSoundIcon from './imgs/speak.svg';
import pauseSoundIcon from './imgs/pause.svg';

const TextReader = ({ audiofile, transcript, pagePosition }) => {

    const [lastPaintedWord, setLastPaintedWord] = useState(1);
    const [isPlaying, setIsPlaying] = useState(false);

    const playerRef = useRef('<HTMLAudioElement>');
    const wordsRef = useRef('<HTMLParagraphElement>');

    const { activePage } = useContext(navContext);

    const audio = `${MEDIA_URL}/${audiofile}`

    const playButtonBG = {
        backgroundImage: `url(${ playSoundIcon })`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%'
    }

    const pauseButtonBG = {
        backgroundImage: `url(${ pauseSoundIcon })`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50%'
    }

    const startRead = () => {
        playerRef.current.play();
        setIsPlaying(true);
    }

    const pauseRead = () => {
        playerRef.current.pause();
        setIsPlaying(false);
    }

    useEffect(() => {
        const onTimeUpdate = () => {
            const activeWordIndex = transcript.words.findIndex((word) => {
                return word.startTime > playerRef.current.currentTime;
              });

            const wordElement = wordsRef.current.childNodes[activeWordIndex];
            //console.log(activeWordIndex);

            if (lastPaintedWord < activeWordIndex -1 ){
                //console.log('paint_skipped!');
                //console.log(lastPaintedWord,activeWordIndex);
                for (let i = lastPaintedWord + 1; i < activeWordIndex; i++) {
                    wordsRef.current.childNodes[i].classList.add('active-word');
                  }
            }

            if(wordElement){
                wordElement.classList.add('active-word');
                setLastPaintedWord(activeWordIndex)
            }

            //console.log(activeWordIndex,transcript.words.length)
            if(activeWordIndex < 0){
                const timeoutId = setTimeout(() => {
                    for (let node of wordsRef.current.childNodes) {
                      node.classList.remove('active-word');
                    }
                    setIsPlaying(false);
                  }, 1000); // 1 second delay

                return () => {
                clearTimeout(timeoutId); // Clear timeout on component unmount or re-render
                };
            }          
            //console.log(playerRef.current.currentTime);

            if(activePage !== pagePosition){
                pauseRead();
            }
        };
        
        const eventTimmer = playerRef.current

        eventTimmer.addEventListener("timeupdate", onTimeUpdate);
        
        return () => eventTimmer.removeEventListener("timeupdate", onTimeUpdate);
    }, [transcript.words, lastPaintedWord, pagePosition, activePage] );

  return(
    <div className="text-anim-container">
        <button className='read-button' style={ isPlaying ? pauseButtonBG : playButtonBG } onClick={ isPlaying ? pauseRead : startRead }>Narrar</button>
        <p className='p-anim' ref={wordsRef}>
            { transcript.words.map((word, i) => word.text !== '~' ? <span key={i}>{ word.text + ' ' }</span>: <span><br/></span> ) }
        </p>
        <audio src={ audio } ref={ playerRef } />
    </div>
  ) 
}

export default TextReader
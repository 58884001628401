import React, { useState, useEffect, useContext } from 'react';
import Slider from 'react-slick';
import Page from './Page';
import { bookContext } from './BookContext';
import { navContext } from './NavContext';
import { sizeContext } from './SizeContext';

import { API_URL, PAGES } from '../config/general';

import "./styles/slick.css";
import "./styles/book.css";


const Book = ({ book_id }) => {

  const { appSize } = useContext(sizeContext);
  const [pages, setPages] = useState([])
  const [activePage, setActivePage] = useState(0)
  const [bookWidth, setBookWidth] = useState(0)

  useEffect(()=> {

    const getPages = async () => {
      try {
        let response = await fetch(`${API_URL}${PAGES}/${book_id}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        let data = await response.json();
        setPages(data);
      } catch (error) {
        // Handle the error
        console.error('Error fetching data:', error.message);
        // You can set an error state or perform other actions as needed
      }
    };  

    getPages();

    if ((appSize[0] / appSize[1]) > 1.95 ){
      // TODO: el valor es 1.78, pero da un problema cuando se acerca a el
      // temporalmente se usa 1.95 para hacer un offset y que las flechas no
      // se escondan
      setBookWidth(appSize[1] * 1.78);
    }
    else{
      setBookWidth(appSize[0] * 0.94);
    }
  }, [appSize,book_id])

  const sizeStyle = {
    width: bookWidth + 'px',
    marginLeft: 'auto',
    marginRight: 'auto',
    '--book-font-size': (bookWidth/40) + 'px',
    '--book-line-height': (bookWidth/30) + 'px'
  }

  const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      //lazyLoad: 'ondemand',
      beforeChange: () => setActivePage(-1),
      afterChange: current => setActivePage(current)
  }
  return (
    <div className='book-16-9' style={ sizeStyle }> 
      <navContext.Provider value={ { activePage } }>
        <Slider {...settings}>
          {pages.map((page, index) => (
            <bookContext.Provider key={index} value={ { 
              text: page.texto, 
              layout: page.layout,
              align: page.align,
              bg: page.ilustracion,
              pagenumber: page.numero,
              pagePosition: index,
              time: page.time,
              offset: page.offset,
              audiofile: page.audiofile
              }}>
              <Page key={ index } />
            </bookContext.Provider>
          ))}
        </Slider>
      </navContext.Provider>
    </div>
  )
}

export default Book
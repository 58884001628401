import React, { useState, useEffect } from 'react'
import Slider from 'react-slick';

import Cover from './Cover'

import { API_URL, BOOKS } from '../config/general';

import "./styles/book_shelf.css";
import bgIMG from "./imgs/bg.jpg";

const bg_style = {
  backgroundImage: `url(${ bgIMG })`,
  backgroundSize: 'cover',
  backgroundPosition: 'bottom'
}

const Shelf = () => {

  let [books, setBooks] = useState([])

  useEffect(()=> {
    const getBooks = async () => {
      try {
        let response = await fetch(`${API_URL}${BOOKS}`);
        
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        
        let data = await response.json();
        setBooks(data);
        if (data.length <= 2){
          import('./styles/no_buttons.css')
          .then((module) => {
            // The imported module contains the styles
            // Apply the styles to the document or specific elements
            const styleElement = document.createElement('style');
            styleElement.innerHTML = module.default; // Access the imported CSS content
            document.head.appendChild(styleElement);
          });
        }

      } catch (error) {
        // Handle the error
        console.error('Error fetching data:', error.message);
        // You can set an error state or perform other actions as needed
      }
    };  

    getBooks();
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    centerMode: (books.length <= 2) ? true : false,
    lazyLoad: 'ondemand',
}

  return (
    <div className={ 'book-shelf' } style={ bg_style }>
      <Slider {...settings}>
        {books.map((book, index) => (
              <Cover key={ index } cover_img={ book.portada } book_id={ book.id } />
          ))}
      </Slider>
    </div>
  )
}

export default Shelf